import React, { Component } from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout'
import './404.scss'

class FourOhFour extends Component {
  render() {
    const {
      location,
    } = this.props;
    return (
      <Layout location={location}>
        <SEO title="Page Not Found | Bellfort" desc="Page Not Found" />
        <section className="error-404-page">
          <div className="wrap">
            <div className="big-error-text">
              <h1>Sorry, we couldn't find that page!</h1>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default FourOhFour;
